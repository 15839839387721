import React, {useState} from 'react'
import {Button, Form, Modal} from "react-bootstrap"
import { useNavigate } from 'react-router-dom'

const Login = () => {

    const [isOpen, setIsOpen] = useState(false)
    const [isOpenTeam, setIsOpenTeam] = useState(false)
    const [name, setName] = useState('')
    const [error, setError] = useState('')
    const history = useNavigate()
    const students = ['Ardeleanu Vlad', 'Groapa Laurentiu', 'Visu Liviu', 'Radoi Andrei', 'Anghel Bogdan']
    const team = ['Dumitru Ion', 'Ene George', 'Dumitru Alexandra Simona', 'Iordache Florian', 'Alecu Cristian', 'Fekete Gabriela']

    const showModalForStudent = () => setIsOpen(true)
    const hideModalForStudent = () => setIsOpen(false)

    const showModalForTeam = () => setIsOpenTeam(true)
    const hideModalForTeam = () => setIsOpenTeam(false)

    const checkUser = () => {
        let index = -1

        if (isOpen) index = students.findIndex(element => element.toLowerCase() === name.toLowerCase())
        else if (isOpenTeam) index = team.findIndex(element => element.toLowerCase() === name.toLowerCase())

        if (index >= 0) {
            localStorage.setItem('name', name.toLowerCase())
            setName('')
            history('/actiune-butoane')
        } else {
            setError('Numele nu apare in lista noastra')
        }
    }

    return (
        <div>
            <div className="d-flex flex-column align-items-center justify-content-center">
                <img
                    src='logo-hyperion.webp'
                    className='img-thumbnail'
                    alt='...'
                    style={{ border:0, width: '20rem', marginTop: '50px', marginBottom: '50px', display: "table", marginLeft: 'auto', marginRight: 'auto' }}
                />

                <h1 style={{display: 'table', marginLeft: 'auto', marginRight: 'auto', marginTop: '50px', color: '#008eff'}}>
                    PROIECT INTERACTIV
                </h1>
                <h1 style={{display: 'table', marginLeft: 'auto', marginRight: 'auto', marginBottom: '50px', color: '#008eff'}}>
                    HYP22
                </h1>
                <h2 style={{display: 'table', marginLeft: 'auto', marginRight: 'auto', marginTop: '100px'}}>Alege tipul de autentificare</h2>

                <div style={{display: 'table', marginLeft: 'auto', marginRight: 'auto', marginTop: '50px'}}>
                    <Button onClick={showModalForTeam} style={{padding: '15px 40px', background: 'blue', border: 0, color: 'white',
                        fontWeight: 'bold', fontSize: '25px', borderRadius: '10px', display: 'table', margin: '15px auto'}}>
                        Parte din Echipa
                    </Button>

                    <Modal
                        size="lg"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                        show={isOpenTeam}
                        onHide={hideModalForTeam}
                    >
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">
                                Autentificare Echipa
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <h5>Introduceti Numele si Prenumele</h5>
                            <Form style={{marginTop: 20}}>
                                <Form.Group className="mb-3" controlId="formGroupName">
                                    <Form.Control onChange={e => setName(e.target.value)} value={name} type="name" placeholder="Nume si Prenume" />
                                    <p style={{color: 'red'}}>{error}</p>
                                </Form.Group>
                            </Form>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button onClick={checkUser}>Acceseaza HYP22</Button>
                        </Modal.Footer>
                    </Modal>


                    <Button onClick={showModalForStudent} style={{padding: '15px 40px', background: 'green', border: 0, color: 'white',
                        fontWeight: 'bold', fontSize: '25px', borderRadius: '10px', display: 'table', margin: '15px auto'}}>
                        Student Universitate
                    </Button>

                    <Modal
                        size="lg"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                        show={isOpen}
                        onHide={hideModalForStudent}
                    >
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">
                                Autentificare Student
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <h5>Introduceti Numele si Prenumele</h5>
                            <Form style={{marginTop: 20}}>
                                <Form.Group className="mb-3" controlId="formGroupName">
                                    <Form.Control onChange={e => setName(e.target.value)} value={name} type="name" placeholder="Nume si Prenume" />
                                    <p style={{color: 'red'}}>{error}</p>
                                </Form.Group>
                            </Form>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button onClick={checkUser}>Acceseaza HYP22</Button>
                        </Modal.Footer>
                    </Modal>
                </div>
            </div>
        </div>
    )
}

export default Login