import {
    BrowserRouter,
    Routes,
    Route,
} from "react-router-dom";
import Login from "./components/Login"
import ControlPage from "./components/ControlPage";

const App = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route exact path='/' element={<Login/>} />
                <Route exact path='/actiune-butoane' element={<ControlPage/>} />
            </Routes>
        </BrowserRouter>
    )
}

export default App
