import React, {useEffect, useState} from 'react'
import {Button} from "react-bootstrap"
import {useNavigate} from "react-router-dom";

const ControlPage = () => {
    const history = useNavigate()
    const disease = {
        humidity: 90,
        temp: 35,
        light: 78
    }
    const [params, setParams] = useState({})
    const [projectOne, setProjectOne] = useState({})
    const [state, setState] = useState({})
    const [index, setIndex] = useState(null)
    const [bool, setBool] = useState(false)
    const [display, setDisplay] = useState({
        'button_1': false,
        'button_2': false,
        'button_3': false,
        'button_4': false,
    })

    useEffect(() => {
        if(!localStorage.getItem('name')) return history('/')
        makeRequest()

        const interval = setInterval(() => {
            makeRequest()
        }, 1000)

        return () => clearInterval(interval)
    }, [bool])

    const setBackground = (index) => {
        setIndex(index)

        setDisplay({
            ...display,
            [`button_${+index}`]: !display[`button_${+index}`]
        })

        setState({
            ...state,
            [index]: !state[index]
        })

        setBool(!bool)
    }

    const hasDisease = target => ((disease.humidity % target.humidity <= 5 && target.humidity <= disease.humidity) || target.humidity > disease.humidity) &&
        ((disease.temp % target.temp <= 5 && target.temp <= disease.temp) || target.temp > disease.temp) &&
        ((disease.light % target.light <= 5 && target.light <= disease.light) || target.light > disease.light)


    const makeRequest = () => {

        fetch(`https://hyp22.glosoft.ro/api/?web&button_${index}=${+state[index]}`, {
            method: "GET",
            headers: {
                'Access-Control-Allow-Origin': '*'
            },
            mode: 'no-cors'
        })
            .then(response => response.json())
            .then(response => {
                setParams({'humidity': response.humidity, 'temp': response.temp, 'light': response.light})
                setProjectOne(response.projectOneData)
                setState({
                    '1': !!Number(response.button_1),
                    '2': !!Number(response.button_2),
                    '3': !!Number(response.button_3),
                    '4': !!Number(response.button_4),
                })
            })
            .catch(err => console.log(err))
    }

    return (
        <div>
            <div className="d-flex flex-column align-items-center justify-content-center">

                <h1 style={{display: 'table', marginLeft: 'auto', marginRight: 'auto', marginTop: '50px', color: '#008eff'}}>
                    PROIECT INTERACTIV
                </h1>
                <h1 style={{display: 'table', marginLeft: 'auto', marginRight: 'auto', marginBottom: '50px', color: '#008eff'}}>
                    HYP22
                </h1>
                <h2 style={{display: 'table', marginLeft: 'auto', marginRight: 'auto', marginTop: '50px'}}>Alege un proiect</h2>
{}
                <div style={{display: 'flex', gap: 20, marginLeft: 'auto', marginRight: 'auto', marginTop: '50px'}}>
                    <Button onClick={() => setBackground(1)} style={{textTransform: 'uppercase', padding: '15px 40px', background: state[1] ? 'green' : 'red', border: 0, color: 'white',
                        fontWeight: 'bold', fontSize: '25px', borderRadius: '10px'}}>
                        Apasa buton proiect 1
                    </Button>
                    <Button onClick={() => setBackground(2)} style={{textTransform: 'uppercase', padding: '15px 40px', background: state[2] ? 'green' : 'red', border: 0, color: 'white',
                        fontWeight: 'bold', fontSize: '25px', borderRadius: '10px'}}>
                        Apasa buton proiect 2
                    </Button>
                </div>

                <div style={{display: 'flex', gap: 20, marginLeft: 'auto', marginRight: 'auto', marginTop: '30px'}}>
                    <Button onClick={() => setBackground(3)} style={{textTransform: 'uppercase', padding: '15px 40px', background: state[3] ? 'green' : 'red', border: 0, color: 'white',
                        fontWeight: 'bold', fontSize: '25px', borderRadius: '10px'}}>
                        Apasa buton proiect 3
                    </Button>
                    <Button onClick={() => setBackground(4)} style={{textTransform: 'uppercase', padding: '15px 40px', background: state[4] ? 'green' : 'red', border: 0, color: 'white',
                        fontWeight: 'bold', fontSize: '25px', borderRadius: '10px'}}>
                        Apasa buton proiect 4
                    </Button>
                </div>


                <div style={{background: '#F2F2F2',marginTop: '50px', marginBottom: '50px', border: '2px solid black', padding: '20px', height: 'fit-content', width: '80%', marginLeft: 'auto', marginRight: 'auto'}}>
                    <div>
                        <h3 style={{textAlign: 'center', fontWeight: 'bold', fontSize: '30px', color: 'blue', textTransform: 'uppercase', marginBottom: '50px'}}>Big Data</h3>
                        <table style={{width: '100%'}}>
                            <tbody>
                            <tr>
                                <td><h4 style={{borderTop: '1px solid black', paddingTop: 30, paddingBottom: 30}}>Umiditate:</h4></td>
                                <td><h4 style={{borderTop: '1px solid black', paddingTop: 30, paddingBottom: 30}}>{params.humidity}%</h4></td>
                            </tr>
                            <tr>
                                <td><h4 style={{borderTop: '1px solid black', paddingTop: 30, paddingBottom: 30}}>Temperatura:</h4></td>
                                <td><h4 style={{borderTop: '1px solid black', paddingTop: 30, paddingBottom: 30}}>{params.temp}C</h4></td>
                            </tr>
                            <tr>
                                <td><h4 style={{borderTop: '1px solid black', paddingTop: 30, paddingBottom: 30}}>Lumina:</h4></td>
                                <td><h4 style={{borderTop: '1px solid black', paddingTop: 30, paddingBottom: 30}}>{params.light} A</h4></td>
                            </tr>
                            <tr>
                                <td><h4 style={{borderTop: '1px solid black', paddingTop: 30, paddingBottom: 30}}>Presiune:</h4></td>
                                <td><h4 style={{borderTop: '1px solid black', paddingTop: 30, paddingBottom: 30}}>{params.light} B</h4></td>
                            </tr>
                            </tbody>
                        </table>

                    </div>
                </div>

                <div style={{width: '80%', display: 'flex', justifyContent: 'space-between', textAlign: 'center', marginLeft: 'auto', marginRight: 'auto',marginBottom: '100px'}}>
                    {state[1] &&
                    <div style={{width: '49%', border: '1px solid black', marginLeft: 'auto', marginRight: 'auto', display: 'table' }}>
                        <h3 style={{textAlign: 'center', fontWeight: 'bold', fontSize: '20px', color: 'blue', textTransform: 'uppercase',
                            marginBottom: '15px', marginTop: '15px'}}>Proiectul Mana</h3>

                        <table style={{ width: '100%' }}>
                            <tbody>
                            <tr>
                                <td style={{borderTop: '1px solid black', fontWeight: 'bold', paddingTop: 10, paddingBottom: 10}}><span>Umiditate</span></td>
                                <td style={{borderTop: '1px solid black', fontWeight: 'bold', paddingTop: 10, paddingBottom: 10}}><span>Temperatura</span></td>
                                <td style={{borderTop: '1px solid black', fontWeight: 'bold', paddingTop: 10, paddingBottom: 10}}><span>Lumina</span></td>
                                <td style={{borderTop: '1px solid black', fontWeight: 'bold', paddingTop: 10, paddingBottom: 10}}><span>Presiune</span></td>
                            </tr>

                            {Object.entries(projectOne).map(([key, v]) => <tr style={{ background: hasDisease(v) && '#ff5050' }}>
                                <td style={{borderTop: '1px solid black', paddingTop: 5, paddingBottom: 5}}><span>{v.humidity}%</span></td>
                                <td style={{borderTop: '1px solid black', paddingTop: 5, paddingBottom: 5}}><span>{v.temp}C</span></td>
                                <td style={{borderTop: '1px solid black', paddingTop: 5, paddingBottom: 5}}><span>{v.light} A</span></td>
                                <td style={{borderTop: '1px solid black', paddingTop: 5, paddingBottom: 5}}><span></span></td>
                            </tr>)}
                            </tbody>
                        </table>
                    </div>
                    }

                    {state[2] &&
                    <div style={{width: '49%', border: '1px solid black', marginLeft: 'auto', marginRight: 'auto', display: 'table'}}>
                        <h3 style={{textAlign: 'center', fontWeight: 'bold', fontSize: '20px', color: 'blue', textTransform: 'uppercase',
                            marginBottom: '15px', marginTop: '15px'}}>Proiectul Mana</h3>

                        <table style={{ width: '100%' }}>
                            <tbody>
                            <tr>
                                <td style={{borderTop: '1px solid black', fontWeight: 'bold', paddingTop: 10, paddingBottom: 10}}><span>Umiditate</span></td>
                                <td style={{borderTop: '1px solid black', fontWeight: 'bold', paddingTop: 10, paddingBottom: 10}}><span>Temperatura</span></td>
                                <td style={{borderTop: '1px solid black', fontWeight: 'bold', paddingTop: 10, paddingBottom: 10}}><span>Lumina</span></td>
                                <td style={{borderTop: '1px solid black', fontWeight: 'bold', paddingTop: 10, paddingBottom: 10}}><spans>Presiune</spans></td>
                            </tr>
                            <tr>
                                <td style={{borderTop: '1px solid black', paddingTop: 5, paddingBottom: 5}}><span>{params.humidity}%</span></td>
                                <td style={{borderTop: '1px solid black', paddingTop: 5, paddingBottom: 5}}><span>{params.temp}C</span></td>
                                <td style={{borderTop: '1px solid black', paddingTop: 5, paddingBottom: 5}}><span>{params.light} A</span></td>
                                <td style={{borderTop: '1px solid black', paddingTop: 5, paddingBottom: 5}}><span>{params.light} B</span></td>
                            </tr>
                            <tr>
                                <td style={{borderTop: '1px solid black', paddingTop: 5, paddingBottom: 5}}><span>{params.humidity}%</span></td>
                                <td style={{borderTop: '1px solid black', paddingTop: 5, paddingBottom: 5}}><span>{params.temp}C</span></td>
                                <td style={{borderTop: '1px solid black', paddingTop: 5, paddingBottom: 5}}><span>{params.light} A</span></td>
                                <td style={{borderTop: '1px solid black', paddingTop: 5, paddingBottom: 5}}><span>{params.light} B</span></td>
                            </tr>
                            <tr>
                                <td style={{borderTop: '1px solid black', paddingTop: 5, paddingBottom: 5}}><span>{params.humidity}%</span></td>
                                <td style={{borderTop: '1px solid black', paddingTop: 5, paddingBottom: 5}}><span>{params.temp}C</span></td>
                                <td style={{borderTop: '1px solid black', paddingTop: 5, paddingBottom: 5}}><span>{params.light} A</span></td>
                                <td style={{borderTop: '1px solid black', paddingTop: 5, paddingBottom: 5}}><span>{params.light} B</span></td>
                            </tr>
                            <tr>
                                <td style={{borderTop: '1px solid black', paddingTop: 5, paddingBottom: 5}}><span>{params.humidity}%</span></td>
                                <td style={{borderTop: '1px solid black', paddingTop: 5, paddingBottom: 5}}><span>{params.temp}C</span></td>
                                <td style={{borderTop: '1px solid black', paddingTop: 5, paddingBottom: 5}}><span>{params.light} A</span></td>
                                <td style={{borderTop: '1px solid black', paddingTop: 5, paddingBottom: 5}}><span>{params.light} B</span></td>
                            </tr>
                            <tr>
                                <td style={{borderTop: '1px solid black', paddingTop: 5, paddingBottom: 5}}><span>{params.humidity}%</span></td>
                                <td style={{borderTop: '1px solid black', paddingTop: 5, paddingBottom: 5}}><span>{params.temp}C</span></td>
                                <td style={{borderTop: '1px solid black', paddingTop: 5, paddingBottom: 5}}><span>{params.light} A</span></td>
                                <td style={{borderTop: '1px solid black', paddingTop: 5, paddingBottom: 5}}><span>{params.light} B</span></td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    }

                </div>


                <div style={{width: '80%', display: 'flex', justifyContent: 'space-between', textAlign: 'center', marginLeft: 'auto', marginRight: 'auto',marginBottom: '100px'}}>
                    {state[3] && <div style={{width: '49%', border: '1px solid black', marginLeft: 'auto', marginRight: 'auto', display: 'table'}}>
                        <h3 style={{textAlign: 'center', fontWeight: 'bold', fontSize: '20px', color: 'blue', textTransform: 'uppercase',
                            marginBottom: '15px', marginTop: '15px'}}>Proiectul Mana</h3>

                        <table style={{ width: '100%' }}>
                            <tbody>
                            <tr>
                                <td style={{borderTop: '1px solid black', fontWeight: 'bold', paddingTop: 10, paddingBottom: 10}}><span>Umiditate</span></td>
                                <td style={{borderTop: '1px solid black', fontWeight: 'bold', paddingTop: 10, paddingBottom: 10}}><span>Temperatura</span></td>
                                <td style={{borderTop: '1px solid black', fontWeight: 'bold', paddingTop: 10, paddingBottom: 10}}><span>Lumina</span></td>
                                <td style={{borderTop: '1px solid black', fontWeight: 'bold', paddingTop: 10, paddingBottom: 10}}><spans>Presiune</spans></td>
                            </tr>
                            <tr>
                                <td style={{borderTop: '1px solid black', paddingTop: 5, paddingBottom: 5}}><span>{params.humidity}%</span></td>
                                <td style={{borderTop: '1px solid black', paddingTop: 5, paddingBottom: 5}}><span>{params.temp}C</span></td>
                                <td style={{borderTop: '1px solid black', paddingTop: 5, paddingBottom: 5}}><span>{params.light} A</span></td>
                                <td style={{borderTop: '1px solid black', paddingTop: 5, paddingBottom: 5}}><span>{params.light} B</span></td>
                            </tr>
                            <tr>
                                <td style={{borderTop: '1px solid black', paddingTop: 5, paddingBottom: 5}}><span>{params.humidity}%</span></td>
                                <td style={{borderTop: '1px solid black', paddingTop: 5, paddingBottom: 5}}><span>{params.temp}C</span></td>
                                <td style={{borderTop: '1px solid black', paddingTop: 5, paddingBottom: 5}}><span>{params.light} A</span></td>
                                <td style={{borderTop: '1px solid black', paddingTop: 5, paddingBottom: 5}}><span>{params.light} B</span></td>
                            </tr>
                            <tr>
                                <td style={{borderTop: '1px solid black', paddingTop: 5, paddingBottom: 5}}><span>{params.humidity}%</span></td>
                                <td style={{borderTop: '1px solid black', paddingTop: 5, paddingBottom: 5}}><span>{params.temp}C</span></td>
                                <td style={{borderTop: '1px solid black', paddingTop: 5, paddingBottom: 5}}><span>{params.light} A</span></td>
                                <td style={{borderTop: '1px solid black', paddingTop: 5, paddingBottom: 5}}><span>{params.light} B</span></td>
                            </tr>
                            <tr>
                                <td style={{borderTop: '1px solid black', paddingTop: 5, paddingBottom: 5}}><span>{params.humidity}%</span></td>
                                <td style={{borderTop: '1px solid black', paddingTop: 5, paddingBottom: 5}}><span>{params.temp}C</span></td>
                                <td style={{borderTop: '1px solid black', paddingTop: 5, paddingBottom: 5}}><span>{params.light} A</span></td>
                                <td style={{borderTop: '1px solid black', paddingTop: 5, paddingBottom: 5}}><span>{params.light} B</span></td>
                            </tr>
                            <tr>
                                <td style={{borderTop: '1px solid black', paddingTop: 5, paddingBottom: 5}}><span>{params.humidity}%</span></td>
                                <td style={{borderTop: '1px solid black', paddingTop: 5, paddingBottom: 5}}><span>{params.temp}C</span></td>
                                <td style={{borderTop: '1px solid black', paddingTop: 5, paddingBottom: 5}}><span>{params.light} A</span></td>
                                <td style={{borderTop: '1px solid black', paddingTop: 5, paddingBottom: 5}}><span>{params.light} B</span></td>
                            </tr>
                            </tbody>
                        </table>
                    </div>}

                    {state[4] && <div style={{width: '49%', border: '1px solid black', marginLeft: 'auto', marginRight: 'auto', display: 'table'}}>
                        <h3 style={{textAlign: 'center', fontWeight: 'bold', fontSize: '20px', color: 'blue', textTransform: 'uppercase',
                            marginBottom: '15px', marginTop: '15px'}}>Proiectul Mana</h3>

                        <table style={{ width: '100%' }}>
                            <tbody>
                            <tr>
                                <td style={{borderTop: '1px solid black', fontWeight: 'bold', paddingTop: 10, paddingBottom: 10}}><span>Umiditate</span></td>
                                <td style={{borderTop: '1px solid black', fontWeight: 'bold', paddingTop: 10, paddingBottom: 10}}><span>Temperatura</span></td>
                                <td style={{borderTop: '1px solid black', fontWeight: 'bold', paddingTop: 10, paddingBottom: 10}}><span>Lumina</span></td>
                                <td style={{borderTop: '1px solid black', fontWeight: 'bold', paddingTop: 10, paddingBottom: 10}}><spans>Presiune</spans></td>
                            </tr>
                            <tr>
                                <td style={{borderTop: '1px solid black', paddingTop: 5, paddingBottom: 5}}><span>{params.humidity}%</span></td>
                                <td style={{borderTop: '1px solid black', paddingTop: 5, paddingBottom: 5}}><span>{params.temp}C</span></td>
                                <td style={{borderTop: '1px solid black', paddingTop: 5, paddingBottom: 5}}><span>{params.light} A</span></td>
                                <td style={{borderTop: '1px solid black', paddingTop: 5, paddingBottom: 5}}><span>{params.light} B</span></td>
                            </tr>
                            <tr>
                                <td style={{borderTop: '1px solid black', paddingTop: 5, paddingBottom: 5}}><span>{params.humidity}%</span></td>
                                <td style={{borderTop: '1px solid black', paddingTop: 5, paddingBottom: 5}}><span>{params.temp}C</span></td>
                                <td style={{borderTop: '1px solid black', paddingTop: 5, paddingBottom: 5}}><span>{params.light} A</span></td>
                                <td style={{borderTop: '1px solid black', paddingTop: 5, paddingBottom: 5}}><span>{params.light} B</span></td>
                            </tr>
                            <tr>
                                <td style={{borderTop: '1px solid black', paddingTop: 5, paddingBottom: 5}}><span>{params.humidity}%</span></td>
                                <td style={{borderTop: '1px solid black', paddingTop: 5, paddingBottom: 5}}><span>{params.temp}C</span></td>
                                <td style={{borderTop: '1px solid black', paddingTop: 5, paddingBottom: 5}}><span>{params.light} A</span></td>
                                <td style={{borderTop: '1px solid black', paddingTop: 5, paddingBottom: 5}}><span>{params.light} B</span></td>
                            </tr>
                            <tr>
                                <td style={{borderTop: '1px solid black', paddingTop: 5, paddingBottom: 5}}><span>{params.humidity}%</span></td>
                                <td style={{borderTop: '1px solid black', paddingTop: 5, paddingBottom: 5}}><span>{params.temp}C</span></td>
                                <td style={{borderTop: '1px solid black', paddingTop: 5, paddingBottom: 5}}><span>{params.light} A</span></td>
                                <td style={{borderTop: '1px solid black', paddingTop: 5, paddingBottom: 5}}><span>{params.light} B</span></td>
                            </tr>
                            <tr>
                                <td style={{borderTop: '1px solid black', paddingTop: 5, paddingBottom: 5}}><span>{params.humidity}%</span></td>
                                <td style={{borderTop: '1px solid black', paddingTop: 5, paddingBottom: 5}}><span>{params.temp}C</span></td>
                                <td style={{borderTop: '1px solid black', paddingTop: 5, paddingBottom: 5}}><span>{params.light} A</span></td>
                                <td style={{borderTop: '1px solid black', paddingTop: 5, paddingBottom: 5}}><span>{params.light} B</span></td>
                            </tr>
                            </tbody>
                        </table>
                    </div>}

                </div>

            </div>
        </div>
    )
}

export default ControlPage